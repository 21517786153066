import './scss/style.scss';
import $ from 'jquery';

// Open and close menu
$('.btn-menu').click(function(){
  const has = $(this).hasClass('active')
  if(!has) {
    $(this).addClass('active')
    $('.menu').addClass('open')
  } else {
    $(this).removeClass('active')
    $('.menu').removeClass('open')
  }
})

// Open and close search
$('.btn-search').click(function(){
  $(this).addClass('active')
  $('.search').addClass('open')
})

$('.btn-submit').click(function(){
  $('.btn-search').removeClass('active')
  $('.search').addClass('open')
})


// Tabs - Acervo button
$('.tabs button').click(function(){
  const type = $(this).attr('data-type')
  const has = $(this).hasClass('current')

  $('.collection').attr('type', type)
  
  if(!has) {
    $('.tabs button').removeClass('current')
    $(this).addClass('current')
  } 
})


// Dropdown filtro - Open and close
$('.filtrar h4').click(function(){
  const el = $(this).parent()
  const has = el.hasClass('open')

  if(!has) {
    $('.filtrar').removeClass('open')
    el.addClass('open')
  } else {
    el.removeClass('open')
  }
})


// Acervo hover 
$('.collection .categories li').hover(function(){
  const value = $(this).attr('value')

  $('.collection .categories li').css('opacity', '.25')
  $(this).css('opacity', '1')
  
  $(`.collection .container .list-view li`).hide();
  $(`.collection .container .list-view li[value="${value}"]`).fadeIn(500);
})


// Filter Programação - Filter
$('.dropdown .btn').click(function(){
  const date = $(this).attr('data-type')
  const clicked = $(this).hasClass('clicked')

  if(clicked) {
    $(this).removeClass('clicked')
    $(this).find('input').removeAttr('checked', false)

    $(`.${date}`).removeClass('show').addClass('hidden')
  } else {
    $(this).addClass('clicked')
    $(this).find('input').attr('checked', true)

    $(`.grid li`).siblings().not(`.${date}`).addClass('hidden')
    $(`.${date}`).addClass('show').removeClass('hidden')
  }

  showAll()
})

function showAll() {
  let array = []
  const allEqual = arr => arr.every( v => v === arr[0] )

  $('.dropdown .btn').each(function(){
    const clicked = $(this).not('.clicked').length
    array.push(clicked)
  })

  const allCheck = allEqual(array)

  if(allCheck) {
    console.log('show all')
    $('.grid li').removeClass('hidden').addClass('show')
  }
}

$('.places li').hover(function(){
  const value = $(this).attr('value')
  $('svg g, .places li').removeClass('active')
  $(`svg .${value}`).addClass('active')
  $(this).addClass('active')
})

// Image Zoom
$('.zoom').mousemove(function( e ) {
  let offsetX, offsetY, x, y
  const zoomer = e.currentTarget

  e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
  e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
  x = offsetX / zoomer.offsetWidth * 100
  y = offsetY / zoomer.offsetHeight * 100

  zoomer.style.backgroundPosition = x + '% ' + y + '%'
});
